import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {CheckboxesStoreProps} from '../../../types/checkoutApp.types';
import {CheckboxesService} from '../../services/checkout/CheckboxesService';
import {CheckoutService} from '../../services/checkout/CheckoutService';
import {CheckoutSettingsService} from '../../services/checkout/CheckoutSettingsService';
import {BIService} from '../../services/checkout/BIService';
import {NavigationService} from '../../services/checkout/NavigationService';
import {FormsService} from '../../services/checkout/FormsService';
import {SPECS} from '../../../common/constants';

export class CheckboxesStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly checkoutService: CheckoutService;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly checkboxesService: CheckboxesService;
  private readonly navigationService: NavigationService;
  private readonly formsService: FormsService;
  private readonly updateComponent: () => void;
  private readonly biService: BIService;

  constructor({
    flowAPI,
    checkoutService,
    checkoutSettingsService,
    checkboxesService,
    updateComponent,
    biService,
    navigationService,
    formsService,
  }: {
    flowAPI: ControllerFlowAPI;
    checkoutService: CheckoutService;
    checkoutSettingsService: CheckoutSettingsService;
    checkboxesService: CheckboxesService;
    updateComponent: () => void;
    biService: BIService;
    navigationService: NavigationService;
    formsService: FormsService;
  }) {
    this.flowAPI = flowAPI;
    this.checkoutService = checkoutService;
    this.checkoutSettingsService = checkoutSettingsService;
    this.checkboxesService = checkboxesService;
    this.updateComponent = updateComponent;
    this.biService = biService;
    this.navigationService = navigationService;
    this.formsService = formsService;
  }

  private readonly areCheckboxesValid = (): boolean => {
    if (this.checkoutService.checkout.customSettings?.hidePolicyAgreementCheckbox) {
      return true;
    }

    return (
      (!this.checkoutSettingsService.checkoutSettings.areGeneralPoliciesEnabled || this.isPolicyCheckboxChecked()) &&
      (!this.checkoutService.checkout.hasDigitalItems ||
        !this.checkoutSettingsService.checkoutSettings.isDigitalPolicyEnabled ||
        this.isDigitalPolicyCheckboxChecked()) &&
      (this.checkoutService.checkout.paymentPolicies.length === 0 || this.isPaymentPolicyCheckboxChecked())
    );
  };

  private readonly isPolicyCheckboxChecked = (): boolean => {
    return (
      this.checkboxesService.isPolicyCheckboxCheckedByUser ??
      this.checkoutSettingsService.checkoutSettings.arePoliciesCheckedByDefault
    );
  };

  private readonly isPaymentPolicyCheckboxChecked = (): boolean => {
    if (!this.flowAPI.experiments.enabled(SPECS.FixClientBIForCardTokenization)) {
      this.biService.checkoutClickOnCheckboxInReviewAndPlaceOrderStepSrc130Evid2({
        checkout: this.checkoutService.checkout,
        fieldType: 'consentRequiredPaymentPolicy',
        newValue: this.checkboxesService.isPaymentPolicyCheckboxCheckedByUser ? 'check' : 'uncheck',
      });
    }
    return this.checkboxesService.isPaymentPolicyCheckboxCheckedByUser;
  };

  private readonly isDigitalPolicyCheckboxChecked = (): boolean => {
    return (
      this.checkboxesService.isDigitalPolicyCheckboxCheckedByUser ??
      this.checkoutSettingsService.checkoutSettings.arePoliciesCheckedByDefault
    );
  };

  private readonly isSubscriptionCheckboxChecked = (): boolean => {
    /* istanbul ignore next */
    const isSubscriptionCheckedByDefault = this.formsService.doesFormHaveSubscription
      ? Boolean(this.formsService.isSubscriptionCheckboxCheckedByDefault)
      : this.checkoutSettingsService.checkoutSettings.isSubscriptionCheckedByDefault;

    return this.checkboxesService.isSubscriptionCheckboxCheckedByUser ?? isSubscriptionCheckedByDefault;
  };

  private readonly toggleIsPaymentPolicyCheckboxChecked = (checked: boolean): void => {
    if (this.flowAPI.experiments.enabled(SPECS.FixClientBIForCardTokenization)) {
      this.biService.checkoutClickOnCheckboxInReviewAndPlaceOrderStepSrc130Evid2({
        checkout: this.checkoutService.checkout,
        fieldType: 'consentRequiredPaymentPolicy',
        newValue: checked ? 'check' : 'uncheck',
      });
    }
    this.checkboxesService.togglePaymentPolicyCheckboxChecked(checked);
    this.updateComponent();
  };

  private readonly toggleIsPolicyCheckboxChecked = (checked: boolean): void => {
    if (this.flowAPI.experiments.enabled(SPECS.FixClientBIForCardTokenization)) {
      this.biService.checkoutClickOnCheckboxInReviewAndPlaceOrderStepSrc130Evid2({
        checkout: this.checkoutService.checkout,
        fieldType: 'checkout polices',
        newValue: checked ? 'check' : 'uncheck',
      });
    }
    this.checkboxesService.togglePolicyCheckboxChecked(checked);
    this.updateComponent();
  };

  private readonly toggleIsDigitalPolicyCheckboxChecked = (checked: boolean): void => {
    if (this.flowAPI.experiments.enabled(SPECS.FixClientBIForCardTokenization)) {
      this.biService.checkoutClickOnCheckboxInReviewAndPlaceOrderStepSrc130Evid2({
        checkout: this.checkoutService.checkout,
        fieldType: 'digital policy',
        newValue: checked ? 'check' : 'uncheck',
      });
    }
    this.checkboxesService.toggleDigitalPolicyCheckboxChecked(checked);
    this.updateComponent();
  };

  private readonly toggleIsSubscriptionCheckboxChecked = (checked: boolean): void => {
    if (this.flowAPI.experiments.enabled(SPECS.FixClientBIForCardTokenization)) {
      this.biService.checkoutClickOnCheckboxInReviewAndPlaceOrderStepSrc130Evid2({
        checkout: this.checkoutService.checkout,
        fieldType: 'subscription policy',
        newValue: checked ? 'check' : 'uncheck',
      });
    }
    this.checkboxesService.toggleSubscriptionCheckboxChecked(checked);
    this.updateComponent();
  };

  private readonly toggleWasFormSubmitted = (wasSubmitted: boolean): void => {
    this.checkboxesService.toggleWasFormSubmitted(wasSubmitted);
    this.updateComponent();
  };

  private readonly shouldShowCheckboxesSection = (): boolean => {
    return (
      this.checkoutSettingsService.checkoutSettings.areGeneralPoliciesEnabled ||
      this.checkoutSettingsService.checkoutSettings.isDigitalPolicyEnabled ||
      this.checkoutSettingsService.checkoutSettings.isSubscriptionEnabled ||
      (this.navigationService.isFastFlow && this.formsService.doesFormHaveSubscription) ||
      this.checkoutService.checkout.paymentPolicies.length > 0
    );
  };

  public toProps(): CheckboxesStoreProps {
    return {
      isPolicyCheckboxChecked: this.isPolicyCheckboxChecked(),
      isPaymentPolicyCheckboxChecked: this.isPaymentPolicyCheckboxChecked(),
      toggleIsPolicyCheckboxChecked: this.toggleIsPolicyCheckboxChecked,
      toggleIsPaymentPolicyCheckboxChecked: this.toggleIsPaymentPolicyCheckboxChecked,
      isDigitalPolicyCheckboxChecked: this.isDigitalPolicyCheckboxChecked(),
      toggleIsDigitalPolicyCheckboxChecked: this.toggleIsDigitalPolicyCheckboxChecked,
      isSubscriptionCheckboxChecked: this.isSubscriptionCheckboxChecked(),
      toggleIsSubscriptionCheckboxChecked: this.toggleIsSubscriptionCheckboxChecked,
      areCheckoutCheckboxesValid: this.areCheckboxesValid(),
      wasFormSubmitted: this.checkboxesService.wasFormSubmittedByUser,
      setWasFormSubmitted: this.toggleWasFormSubmitted,
      shouldShowCheckboxesSection: this.shouldShowCheckboxesSection(),
    };
  }
}
